import { InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import * as React from 'react';
import { useVerwaltung } from 'src/modules/Einstellungen/hooks';
import { useSearchQuery } from 'src/utils/hooks/search-query';

export const SEARCH_FILTER_KEY = 'search';

export const SearchFilter = (props) => {
  const { filterData, replaceInSearchQuery, removeFromSearchQuery } =
    useSearchQuery();
  const {
    verwaltung: { isLiteVersion },
  } = useVerwaltung();

  const [previousSearch, setPreviousSearch] = React.useState<string>('');

  const filterQueryKategorie = filterData[SEARCH_FILTER_KEY];
  const search = React.useMemo(
    () =>
      typeof filterQueryKategorie === 'object'
        ? filterQueryKategorie[0]
        : filterQueryKategorie,
    [filterQueryKategorie]
  );

  const handleSearchChange = React.useCallback(
    ({ target: { value } }) => {
      if (!!value) {
        setPreviousSearch(value.toLowerCase());
        replaceInSearchQuery(SEARCH_FILTER_KEY, value.toLowerCase());
      } else {
        removeFromSearchQuery(SEARCH_FILTER_KEY, previousSearch);
      }
    },
    [previousSearch, removeFromSearchQuery, replaceInSearchQuery]
  );

  return !isLiteVersion ? (
    <TextField
      variant="standard"
      value={search || null}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      onChange={handleSearchChange}
      {...props}
    />
  ) : null;
};
