import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { FieldArray, getIn, useField, useFormikContext } from 'formik';
import * as React from 'react';
import * as Yup from 'yup';

import LimboFormikDatePicker from 'src/components/atoms/form-fields/limboDatePicker';
import LimboFormikSelect from 'src/components/atoms/form-fields/limboSelect';
import LimboFormikTextField from 'src/components/atoms/form-fields/limboTextField';
import { DeleteIcon } from 'src/components/atoms/icons';
import LimboToolTip from 'src/components/molecules/limbo-tool-tip';
import {
  EmissionsFaktorTyp,
  EnergieSparte,
  ErfassungsTyp,
  HeizungsTyp,
  useConfig,
} from 'src/modules/Config/hooks';
import {
  PARENTZAEHLER_SELECT,
  UNTERZAEHLER_SELECT,
} from 'src/modules/Liegenschaften/components/forms/fields';
import { validate } from 'src/utils/yup';

import { Edit } from '@material-ui/icons';
import { AddOutlinedButton } from 'src/components/atoms/buttons/add-button-outline';
import LimboFormikSwitch from 'src/components/atoms/form-fields/limbo-switch';
import LimboFormikNumberField from 'src/components/atoms/form-fields/limboNumberField';
import { TooltipPopper } from 'src/components/atoms/tooltip';
import { validateDecimalPlacesOfNumber } from 'src/utils/formatNumbers';
import { marloRegExp, meloRegExp } from 'src/utils/patterns';
import { HEIZUNGS_TYP_WAERMEPUMPE } from '../../constants';
import { getUnitByType } from '../../helper';
import {
  DeleteZaehlerLine,
  LimboFormikZaehlernummern,
  ZAEHLERNUMMER,
} from './limboZaehlernummernFieldArray';
import { increasingDate, uniqueDate } from './validation';

export const NAME_DES_ZAEHLPUNKTES = {
  name: 'name',
  label: 'Name des Zählpunkts',
  tooltip: 'z.b. Stromzähler im Keller / Ölheizung',
  validationSchema: validate(Yup.string().required('Bitte Name eingeben')),
};

export const NameDesZaehlpunktes = (props: any) => (
  <LimboFormikTextField
    name={NAME_DES_ZAEHLPUNKTES.name}
    label={NAME_DES_ZAEHLPUNKTES.label}
    tooltip={NAME_DES_ZAEHLPUNKTES.tooltip}
    validate={NAME_DES_ZAEHLPUNKTES.validationSchema}
    required
    {...props}
  />
);

export const ZAEHLPUNKT_STATUS = {
  name: 'status',
  label: (state) => `Status des Zählpunktes${state ? `: ${state}` : ''}`,
};

export const ZaehlpunktStatus = ({
  label = ZAEHLPUNKT_STATUS.label,
  name = ZAEHLPUNKT_STATUS.name,
  disabled,
}) => {
  const [{ value }] = useField(name);

  return (
    <LimboFormikSwitch
      label={label(value ? 'aktiv' : 'inaktiv')}
      name={name}
      disabled={disabled}
    />
  );
};

export const ZAEHLPUNKT_TYPE = {
  name: 'zaehlpunktTyp',
  label: 'Art des Zählpunktes auswählen',
  validationSchema: validate(Yup.string().required('Bitte auswählen')),
};

export const ZaehlpunktTyp = ({ ...props }) => {
  const { disabled, isCreateMode, ...selectProps } = props;
  const { config } = useConfig();
  const { setFieldValue } = useFormikContext();

  // RESET SELECTION WHEN ZAEHLPUNKT TYPE CHANGES
  const handleChange = React.useCallback(() => {
    setFieldValue(UNTERZAEHLER_SELECT.name, '');
    setFieldValue(PARENTZAEHLER_SELECT.name, '');
  }, [setFieldValue]);

  return (
    <LimboFormikSelect
      name={ZAEHLPUNKT_TYPE.name}
      label={ZAEHLPUNKT_TYPE.label}
      validate={ZAEHLPUNKT_TYPE.validationSchema}
      onChange={handleChange}
      data={config.energieSparte}
      disabled={disabled || !isCreateMode}
      required
      {...selectProps}
    />
  );
};

export const ZAEHLER_RELATIONS_TYP = {
  name: 'zaehlerRelationsTyp',
  label: 'Abhängigkeiten',
  tooltip:
    'Wählen Sie diese Option wenn der Zählpunkt entweder Unterzählpunkte hat, oder ein Unterzählpunkt eines anderen Zählpunktes ist',
  validationSchema: validate(Yup.string().required('Bitte auswählen')),
  defaultValue: 0,
};

export const ZaehlerRelationsTypen = ({ ...props }) => {
  const {
    config: { zaehlerRelationsTyp },
  } = useConfig();
  const { setFieldValue } = useFormikContext();

  // RESET SELECTION WHEN RELATION TYPE CHANGES
  const handleChange = React.useCallback(() => {
    setFieldValue(UNTERZAEHLER_SELECT.name, '');
    setFieldValue(PARENTZAEHLER_SELECT.name, '');
  }, [setFieldValue]);

  return (
    <LimboFormikSelect
      name={ZAEHLER_RELATIONS_TYP.name}
      label={ZAEHLER_RELATIONS_TYP.label}
      tooltip={ZAEHLER_RELATIONS_TYP.tooltip}
      validate={ZAEHLER_RELATIONS_TYP.validationSchema}
      defaultValue={ZAEHLER_RELATIONS_TYP.defaultValue}
      onChange={handleChange}
      data={zaehlerRelationsTyp}
      required
      style={{ paddingBottom: 0 }}
      {...props}
    />
  );
};

export const ZAEHLPUNKT_BRENNWERT = {
  label: 'Brennwert in kWh/m³',
  fieldName: 'brennwert',
  name: (index: number) =>
    `${ZEITLICHE_BRENNWERTE.name}.${index}.${ZAEHLPUNKT_BRENNWERT.fieldName}`,
  validationSchema: validate(
    Yup.number()
      .typeError('Bitte Brennwert eingeben.')
      .required('Bitte Brennwert eingeben.')
      .min(5, 'Der Brennwert muss mindestens 5 kWh/m³ betragen.')
      .max(15, 'Der Brennwert darf maximal 15 kWh/m³ betragen.')
      .test({
        message: 'Bitte nur 3 Nachkommastellen eingeben',
        test: validateDecimalPlacesOfNumber(3),
      })
  ),
};

export const ZaehlpunktBrennwert = (props: any) => {
  const { index, ...brennwertProps } = props;
  return (
    <LimboFormikNumberField
      name={ZAEHLPUNKT_BRENNWERT.name(index)}
      label={ZAEHLPUNKT_BRENNWERT.label}
      validate={ZAEHLPUNKT_BRENNWERT.validationSchema}
      decimalScale={3}
      required
      {...brennwertProps}
    />
  );
};

const ZAEHLPUNKT_BRENNWERTE_GUELTIG_BIS = {
  label: 'Gültig bis',
  fieldName: 'gueltigBis',
  name: (index: number) =>
    `${ZEITLICHE_BRENNWERTE.name}.${index}.${ZAEHLPUNKT_BRENNWERTE_GUELTIG_BIS.fieldName}`,
  validationSchema: (brennwerte: Array<{ gueltigBis?: string }>) =>
    validate(
      Yup.date()
        .required('Bitte Datum auswählen')
        .typeError('Bitte Datum auswählen')
        .max(new Date(), 'Datum darf nicht in der Zukunft liegen')
        .test({
          message: 'Datum muss eindeutig sein',
          test: uniqueDate(
            ZAEHLPUNKT_BRENNWERTE_GUELTIG_BIS.fieldName,
            brennwerte
          ),
        })
        .test({
          message: 'Datum muss aufsteigend sein',
          test: increasingDate(
            ZAEHLPUNKT_BRENNWERTE_GUELTIG_BIS.fieldName,
            brennwerte
          ),
        })
    ),
};

const BrennwerteGueltigBis = (props: any) => {
  const { index, ...datePickerProps } = props;
  const { values } = useFormikContext();
  const brennwerte = getIn(values, ZEITLICHE_BRENNWERTE.name);

  return (
    <LimboFormikDatePicker
      name={ZAEHLPUNKT_BRENNWERTE_GUELTIG_BIS.name(index)}
      label={ZAEHLPUNKT_BRENNWERTE_GUELTIG_BIS.label}
      validate={ZAEHLPUNKT_BRENNWERTE_GUELTIG_BIS.validationSchema(brennwerte)}
      maxDate={new Date()}
      dateFormat="yyyy-MM-dd"
      KeyboardButtonProps={{
        disabled: true,
        style: { display: 'none' },
      }}
      {...datePickerProps}
    />
  );
};

export const ZEITLICHE_BRENNWERTE = {
  name: 'brennwerte',
  validationSchema: validate(
    Yup.array().of(
      Yup.object().shape({
        [ZAEHLPUNKT_BRENNWERT.fieldName]: Yup.number().required(),
        [ZAEHLPUNKT_BRENNWERTE_GUELTIG_BIS.fieldName]: Yup.date(),
      })
    )
  ),
  defaultValues: [
    {
      [ZAEHLPUNKT_BRENNWERT.fieldName]: null,
    },
  ],
};

export const ZeitlicheBrennwerte = (props: any) => {
  const [rowsToEdit, setRowsToEdit] = React.useState<Array<number>>([]);
  const [newAddedRows, setNewAddedRows] = React.useState<Array<number>>([]);
  const [isCreatedMode, setIsCreateMode] = React.useState(false);
  const { disabled } = props;
  const [field, , { setValue }] = useField<
    Array<{ [key: string]: string | null }>
  >({
    name: ZEITLICHE_BRENNWERTE.name,
    validate: ZEITLICHE_BRENNWERTE.validationSchema,
  });

  React.useEffect(() => {
    if (!field.value) {
      setValue(ZEITLICHE_BRENNWERTE.defaultValues);
      setIsCreateMode(true);
    }
  }, [field.value, setValue]);

  const handleAddWerte = React.useCallback(() => {
    setValue([...field.value, ZEITLICHE_BRENNWERTE.defaultValues[0]]);
    const newRows = [...newAddedRows, field.value.length];
    setNewAddedRows(newRows);
  }, [field.value, newAddedRows, setValue]);

  const handleRowDelete = React.useCallback(
    (indexToDelete) => {
      const lastItem = field.value.length - 1;
      const newFieldArray = [
        ...field.value.slice(0, indexToDelete),
        ...field.value.slice(indexToDelete + 1),
      ];
      if (indexToDelete === lastItem) {
        const [last, ...items] = [...newFieldArray].reverse();

        return setValue([
          ...items.reverse(),
          {
            [ZAEHLPUNKT_BRENNWERT.fieldName]:
              last[ZAEHLPUNKT_BRENNWERT.fieldName],
          },
        ]);
      }
      setValue(newFieldArray);
      const newRows = newAddedRows
        .filter((rowIndex) => rowIndex !== indexToDelete)
        .map((index) => (index > indexToDelete ? index - 1 : index));
      setNewAddedRows(newRows);
    },
    [field.value, newAddedRows, setValue]
  );

  const handleEditRow = React.useCallback(
    (index: number) => {
      const newRowsToEdit = [...rowsToEdit, index];
      setRowsToEdit(newRowsToEdit);
    },
    [rowsToEdit]
  );

  return (
    <>
      <FieldArray
        name={ZEITLICHE_BRENNWERTE.name}
        render={() => (
          <>
            {field.value?.map((record, index) => {
              const isLastEntry = index === field.value.length - 1;
              const showEditButton =
                !isCreatedMode &&
                !rowsToEdit.includes(index) &&
                !newAddedRows.includes(index);
              const showDeleteButton =
                (isCreatedMode && field?.value?.length > 1) ||
                (!isCreatedMode && newAddedRows.includes(index));

              return (
                <Grid key={index} container direction="row" spacing={4}>
                  <Grid item xs={6}>
                    <ZaehlpunktBrennwert
                      index={index}
                      value={record[ZAEHLPUNKT_BRENNWERT.fieldName]}
                      disabled={disabled || showEditButton}
                      required
                    />
                  </Grid>
                  {!isLastEntry && (
                    <Grid item xs={5}>
                      <BrennwerteGueltigBis
                        index={index}
                        value={
                          record[ZAEHLPUNKT_BRENNWERTE_GUELTIG_BIS.fieldName]
                        }
                        disabled={
                          disabled ||
                          (showEditButton && !newAddedRows.includes(index + 1))
                        }
                        required
                      />
                    </Grid>
                  )}
                  {showDeleteButton && (
                    <>
                      {isLastEntry && <Grid item xs={5}></Grid>}
                      <Grid item xs={1}>
                        <IconButton
                          onClick={() => handleRowDelete(index)}
                          disabled={disabled}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </>
                  )}
                  {showEditButton && (
                    <Grid item xs={1}>
                      <Tooltip
                        interactive
                        title="Bitte nutzen Sie die Bearbeitenfunktion nur um
                          historische Daten zu korrigieren."
                        PopperComponent={TooltipPopper(
                          <Typography>
                            Bitte nutzen Sie die Bearbeitenfunktion nur um
                            fehlerhafte historische Daten zu korrigieren. Für
                            Änderungen an dem Brennwert legen Sie bitte über die
                            Schaltfläche "Neueren Wert hinzufügen" einen neuen
                            Eintrag an.
                          </Typography>
                        )}
                      >
                        <IconButton
                          onClick={() => handleEditRow(index)}
                          disabled={disabled}
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
              );
            })}
          </>
        )}
      />
      <Grid item xs={6} style={{ marginTop: '0.5rem', marginBottom: '2rem' }}>
        <AddOutlinedButton onClick={handleAddWerte} disabled={disabled}>
          Neueren Wert hinzufügen
        </AddOutlinedButton>
      </Grid>
    </>
  );
};

export const ZAEHLERNUMMERN = {
  name: 'zaehlernummern',
  label: 'Zählernummern',
  requiredErrorMsg: '',
  tooltip: '',
  validationSchema: validate(
    Yup.array().of(
      Yup.object().shape({
        gueltigBis: Yup.date(),
        zaehlernummer: Yup.string().required('Bitte Zählernummer eintragen'),
      })
    )
  ),
  defaultValue: [
    {
      [ZAEHLERNUMMER.fieldName]: '',
    },
  ],
};

export const Zaehlernummern = (props: any) => {
  const { values } = useFormikContext();
  const erfassungsType = getIn(values, ZAEHLER_DATA_UNIT.name);
  const zaehlerTyp = getIn(values, ZAEHLPUNKT_TYPE.name);

  return (
    <LimboFormikZaehlernummern
      name={ZAEHLERNUMMERN.name}
      validationSchema={ZAEHLERNUMMERN.validationSchema}
      defaultValue={ZAEHLERNUMMERN.defaultValue}
      erfassungsTyp={erfassungsType}
      zaehlerTyp={props.zaehlerTyp ?? zaehlerTyp}
      disabled={props.disabled}
    />
  );
};

export const ZAEHLER_BEMERKUNG = {
  name: 'bemerkungen',
  label: 'Bemerkungen zum Zählpunkt',
  requiredErrorMsg: '',
  tooltip: 'Hier ist Platz für Ihre Notizen zum Zählpunkt',
};

export const ZaehlerBemerkung = (props: any) => (
  <LimboFormikTextField
    name={ZAEHLER_BEMERKUNG.name}
    label={ZAEHLER_BEMERKUNG.label}
    tooltip={ZAEHLER_BEMERKUNG.tooltip}
    rows="8"
    multiline
    fullWidth
    {...props}
  />
);

const ZAEHLER_GESAMTLEISTUNG = {
  name: 'gesamtleistung',
  label: 'Gesamtleistung in kWp',
};

export const ZaehlerGesamtleistung = (props: any) => (
  <LimboFormikTextField
    name={ZAEHLER_GESAMTLEISTUNG.name}
    label={ZAEHLER_GESAMTLEISTUNG.label}
    {...props}
  />
);

export const ZAEHLER_MESSLOKATION = {
  name: 'messlokation',
  label: 'Messlokation',
  validationSchema: validate(
    Yup.lazy((value) =>
      value !== ''
        ? Yup.string().matches(
            meloRegExp,
            'Die Messlokation muss mit DE starten und nachfolgend aus 31 alphanumerisches Zeichen bestehen'
          )
        : Yup.string()
    )
  ),
};

export const ZaehlerMesslokation = (props: any) => (
  <LimboFormikTextField
    name={ZAEHLER_MESSLOKATION.name}
    label={ZAEHLER_MESSLOKATION.label}
    validate={ZAEHLER_MESSLOKATION.validationSchema}
    inputProps={{ maxLength: 33 }}
    {...props}
  />
);

export const ZAEHLER_MARKTLOKATION = {
  name: 'marktlokation',
  label: 'Marktlokation',
  validationSchema: validate(
    Yup.lazy((value) =>
      value !== ''
        ? Yup.string().matches(
            marloRegExp,
            'Die Marktlokation muss aus 11 Ziffern bestehen'
          )
        : Yup.string()
    )
  ),
};

export const ZaehlerMarktlokation = (props: any) => (
  <LimboFormikTextField
    name={ZAEHLER_MARKTLOKATION.name}
    label={ZAEHLER_MARKTLOKATION.label}
    validate={ZAEHLER_MARKTLOKATION.validationSchema}
    inputProps={{ maxLength: 11, pattern: '[0-9]{11}' }}
    {...props}
  />
);

export const ZAEHLER_DATA_UNIT = {
  name: 'erfassungsTyp',
  tooltip:
    'Hiermit legen Sie fest, wie historische und aktuelle Bewegungsdaten erfasst werden. Diese Einstellung lässt sich nachträglich nicht mehr ändern.',
  validationSchema: validate(Yup.number().required('Bitte auswählen')),
};

export const ZaehlerSelectDataUnit = ({ disabled }: any) => {
  const { config } = useConfig();
  const { values: formValues, setFieldValue } = useFormikContext();
  const zaehlerTyp = getIn(formValues, ZAEHLPUNKT_TYPE.name);
  const heizungsTyp = getIn(formValues, ZAEHLPUNKT_HEIZUNGSTYP.name);
  const zaehlerDataUnit = getIn(formValues, ZAEHLER_DATA_UNIT.name);

  React.useEffect(() => {
    if (heizungsTyp === HEIZUNGS_TYP_WAERMEPUMPE.value) {
      setFieldValue(ZAEHLER_DATA_UNIT.name, ErfassungsTyp.Zaehlerstand);
    }
  }, [heizungsTyp, setFieldValue, zaehlerTyp]);

  const handleOnChange = React.useCallback(
    ({ target: { value } }) => {
      setFieldValue(ZAEHLER_DATA_UNIT.name, Number(value));
    },
    [setFieldValue]
  );
  return (
    <FormControl
      component="fieldset"
      style={{ width: '100%' }}
      disabled={disabled}
    >
      <FormLabel component="legend" style={{ marginBottom: '1rem' }}>
        Wie möchten Sie die Daten erfassen?*
      </FormLabel>
      {ZAEHLER_DATA_UNIT.tooltip && (
        <LimboToolTip
          tooltip={<Typography>{ZAEHLER_DATA_UNIT.tooltip}</Typography>}
        />
      )}
      <RadioGroup
        aria-label="zaehler-datenformat"
        name={ZAEHLER_DATA_UNIT.name}
        value={zaehlerDataUnit || ''}
        onChange={handleOnChange}
      >
        <Grid container direction="row" spacing={4}>
          {config.erfassungsTyp?.map((ef: any) => (
            <Grid key={ef.value} item xs={6}>
              <FormControlLabel
                value={ef.value}
                control={<Radio required />}
                label={`${ef.label} in ${getUnitByType({
                  zaehlpunktTyp:
                    heizungsTyp === HeizungsTyp.Erdgas
                      ? EnergieSparte.Gas
                      : zaehlerTyp,
                })}`}
              />
            </Grid>
          ))}
        </Grid>
      </RadioGroup>
    </FormControl>
  );
};

ZaehlerSelectDataUnit.defaultProps = {
  readOnly: false,
};

export const ZAEHLPUNKT_HEIZUNGSTYP = {
  name: 'heizungsartRaumwaerme',
  label: 'Heizungsart',
  validationSchema: validate(
    Yup.number().required('Bitte Art des Heizungsart auswählen')
  ),
};

export const ZaehlpunktHeizungsTyp = ({ disabled, ...props }: any) => {
  const { config } = useConfig();

  return (
    <LimboFormikSelect
      name={ZAEHLPUNKT_HEIZUNGSTYP.name}
      label={ZAEHLPUNKT_HEIZUNGSTYP.label}
      data={config.heizungsTyp}
      validate={ZAEHLPUNKT_HEIZUNGSTYP.validationSchema}
      required
      disabled={disabled}
      {...props}
    />
  );
};

const ZAEHLER_EMISSIONSFAKTOR = {
  fieldName: 'emissionsfaktor',
  name: (index: number) =>
    `${ZAEHLER_EMISSIONSFAKTOREN.name}.${index}.${ZAEHLER_EMISSIONSFAKTOR.fieldName}`,
  label: 'Emissionsfaktor in g/kWh',
  tooltip:
    'Den Emissionsfaktor zu Ihrem Stromtarif (z.B. Ökostrom) finden Sie in der Regel auf den Abrechnungen Ihres Stromanbieters.',
  validationSchema: validate(
    Yup.number()
      .required('Bitte Emissionsfaktor eingeben')
      .typeError('Bitte Emissionsfaktor eingeben')
      .min(0, 'Der Emissionsfaktor muss mindest 0 sein')
      .test({
        message: 'Bitte keine Nachkommastellen eingeben',
        test: validateDecimalPlacesOfNumber(),
      })
  ),
};

const ZaehlerEmissionsfaktor = ({ disabled, index, ...props }: any) => {
  return (
    <LimboFormikNumberField
      name={ZAEHLER_EMISSIONSFAKTOR.name(index)}
      label={ZAEHLER_EMISSIONSFAKTOR.label}
      validate={ZAEHLER_EMISSIONSFAKTOR.validationSchema}
      tooltip={ZAEHLER_EMISSIONSFAKTOR.tooltip}
      disabled={disabled}
      decimalScale={0}
      required
      {...props}
    />
  );
};

export const ZAEHLER_EMISSIONS_FAKTOR_TYP = {
  fieldName: 'emissionsFaktorTyp',
  name: (index: number) =>
    `${ZAEHLER_EMISSIONSFAKTOREN.name}.${index}.${ZAEHLER_EMISSIONS_FAKTOR_TYP.fieldName}`,
  validationSchema: validate(Yup.number().required('Bitte auswählen')),
};

const ZaehlerEmissionsFaktorTyp = ({ disabled, index, zaehlpunktTyp }: any) => {
  const { config } = useConfig();
  const { values: formValues, setFieldValue } = useFormikContext();
  const emissionsFaktorTyp = getIn(
    formValues,
    ZAEHLER_EMISSIONS_FAKTOR_TYP.name(index)
  );
  const emissionsfaktor = getIn(
    formValues,
    ZAEHLER_EMISSIONSFAKTOR.name(index)
  );

  const handleOnChange = React.useCallback(
    ({ target: { value } }) => {
      setFieldValue(ZAEHLER_EMISSIONS_FAKTOR_TYP.name(index), Number(value));
    },
    [index, setFieldValue]
  );

  const removeCurrentEmissionsfaktor = React.useCallback(() => {
    const emissionsfaktoren = getIn(formValues, ZAEHLER_EMISSIONSFAKTOREN.name);
    const currentEmissionSettings = emissionsfaktoren[index];
    const { emissionsfaktor, ...remainingEmissionSettings } =
      currentEmissionSettings;
    setFieldValue(ZAEHLER_EMISSIONSFAKTOREN.name, [
      ...emissionsfaktoren.slice(0, index),
      remainingEmissionSettings,
      ...emissionsfaktoren.slice(index + 1),
    ]);
  }, [formValues, index, setFieldValue]);

  React.useEffect(() => {
    if (emissionsFaktorTyp === EmissionsFaktorTyp.Standard && emissionsfaktor) {
      removeCurrentEmissionsfaktor();
    }
  }, [emissionsFaktorTyp, emissionsfaktor, removeCurrentEmissionsfaktor]);

  return config?.emissionsFaktorTypen ? (
    <FormControl
      component="fieldset"
      style={{ width: '100%' }}
      disabled={disabled}
    >
      <FormLabel component="legend" style={{ marginBottom: '1rem' }}>
        Art des Emissionsfaktors wählen*
      </FormLabel>
      <RadioGroup
        aria-label="zaehler-datenformat"
        name={ZAEHLER_EMISSIONS_FAKTOR_TYP.name(index)}
        value={emissionsFaktorTyp || ''}
        onChange={handleOnChange}
      >
        <Grid container direction="row" spacing={4}>
          {config?.emissionsFaktorTypen[zaehlpunktTyp]?.map((et: any) => (
            <Grid key={et.value} item xs={6}>
              <FormControlLabel
                value={et.value}
                control={<Radio required />}
                label={et.label}
              />
            </Grid>
          ))}
        </Grid>
      </RadioGroup>
    </FormControl>
  ) : null;
};

const EMISSIONSFAKTOR_GUELTIG_BIS = {
  fieldName: 'gueltigBis',
  name: (index: number) =>
    `${ZAEHLER_EMISSIONSFAKTOREN.name}.${index}.${EMISSIONSFAKTOR_GUELTIG_BIS.fieldName}`,
  label: 'Gültig bis',
  validationSchema: validate(
    Yup.date()
      .required('Bitte Datum auswählen')
      .typeError('Bitte Datum auswählen')
  ),
};

const EmissionsfaktorGueltigBis = ({ disabled, index, ...props }: any) => (
  <LimboFormikDatePicker
    name={EMISSIONSFAKTOR_GUELTIG_BIS.name(index)}
    label={EMISSIONSFAKTOR_GUELTIG_BIS.label}
    validate={EMISSIONSFAKTOR_GUELTIG_BIS.validationSchema}
    disabled={disabled}
    dateFormat="yyyy-MM-dd"
    required
    {...props}
  />
);

export const ZAEHLER_EMISSIONSFAKTOREN = {
  name: 'emissionsfaktoren',
  label: 'Emissionsfaktoren',
  validationSchema: validate(
    Yup.array().of(
      Yup.object().shape({
        gueltigBis: Yup.date(),
        emissionsFaktorTyp: Yup.number().required('Bitte auswählen'),
      })
    )
  ),
  defaultValue: [
    {
      [ZAEHLER_EMISSIONS_FAKTOR_TYP.fieldName]: 1,
    },
  ],
};

export const ZaehlerEmissionsfaktoren = (props: any) => {
  const [rowsToEdit, setRowsToEdit] = React.useState<Array<number>>([]);
  const [newAddedRows, setNewAddedRows] = React.useState<Array<number>>([]);
  const [isCreatedMode, setIsCreateMode] = React.useState(false);
  const [field, , { setValue }] = useField<
    Array<{ [key: string]: string | number | null }>
  >({
    name: ZAEHLER_EMISSIONSFAKTOREN.name,
    validate: ZAEHLER_EMISSIONSFAKTOREN.validationSchema,
  });
  const { values } = useFormikContext();

  const handleAddWerte = React.useCallback(() => {
    setValue([...field.value, ZAEHLER_EMISSIONSFAKTOREN.defaultValue[0]]);
    const newRows = [...newAddedRows, field.value.length];
    setNewAddedRows(newRows);
  }, [field.value, newAddedRows, setValue]);

  React.useEffect(() => {
    if (!field.value) {
      setValue(ZAEHLER_EMISSIONSFAKTOREN.defaultValue);
      setIsCreateMode(true);
    }
  }, [field.value, setValue]);

  const handleDelete = React.useCallback(
    (indexToDelete) => {
      const lastItem = field.value.length - 1;
      const newFieldArray = [
        ...field.value.slice(0, indexToDelete),
        ...field.value.slice(indexToDelete + 1),
      ];
      if (indexToDelete === lastItem) {
        const [last, ...items] = [...newFieldArray].reverse();

        return setValue([
          ...items.reverse(),
          {
            [ZAEHLER_EMISSIONS_FAKTOR_TYP.fieldName]:
              last[ZAEHLER_EMISSIONS_FAKTOR_TYP.fieldName],
            [ZAEHLER_EMISSIONSFAKTOR.fieldName]:
              last[ZAEHLER_EMISSIONSFAKTOR.fieldName],
          },
        ]);
      }
      setValue(newFieldArray);
      const newRows = newAddedRows
        .filter((rowIndex) => rowIndex !== indexToDelete)
        .map((index) => (index > indexToDelete ? index - 1 : index));
      setNewAddedRows(newRows);
    },
    [field.value, newAddedRows, setValue]
  );

  const handleEditRow = React.useCallback(
    (index: number) => {
      const newRowsToEdit = [...rowsToEdit, index];
      setRowsToEdit(newRowsToEdit);
    },
    [rowsToEdit]
  );

  return (
    <Grid container direction="row" spacing={4} style={{ marginTop: '2rem' }}>
      <Grid item xs={12}>
        <FieldArray
          name={ZAEHLER_EMISSIONSFAKTOREN.name}
          render={() => (
            <>
              {field.value?.map((record, index) => {
                const emissionsFaktorTyp = getIn(
                  values,
                  ZAEHLER_EMISSIONS_FAKTOR_TYP.name(index)
                );
                const isIndividualEmission =
                  emissionsFaktorTyp === EmissionsFaktorTyp.Individual;
                const isLastEntry = index === field.value.length - 1;
                const showEditButton =
                  !isCreatedMode &&
                  !rowsToEdit.includes(index) &&
                  !newAddedRows.includes(index);
                const showDeleteButton =
                  (isCreatedMode && field?.value?.length > 1) ||
                  (!isCreatedMode && newAddedRows.includes(index));

                return (
                  <Grid key={index} container direction="row" spacing={4}>
                    <Grid item xs={5}>
                      <ZaehlerEmissionsFaktorTyp
                        index={index}
                        value={record[ZAEHLER_EMISSIONS_FAKTOR_TYP.fieldName]}
                        disabled={props.disabled || showEditButton}
                        zaehlpunktTyp={props.zaehlpunktTyp}
                      />
                    </Grid>
                    {isIndividualEmission && (
                      <Grid
                        item
                        xs={
                          !isLastEntry
                            ? 4
                            : showDeleteButton || showEditButton
                            ? 6
                            : 7
                        }
                        style={{ marginTop: '1.5rem' }}
                      >
                        <ZaehlerEmissionsfaktor
                          index={index}
                          value={record[ZAEHLER_EMISSIONSFAKTOR.fieldName]}
                          disabled={props.disabled || showEditButton}
                        />
                      </Grid>
                    )}
                    {!isLastEntry && (
                      <Grid
                        item
                        xs={isIndividualEmission ? 2 : 6}
                        style={{ marginTop: '1.5rem' }}
                      >
                        <EmissionsfaktorGueltigBis
                          index={index}
                          value={record[EMISSIONSFAKTOR_GUELTIG_BIS.fieldName]}
                          disabled={
                            props.disabled ||
                            (showEditButton &&
                              !newAddedRows.includes(index + 1))
                          }
                          KeyboardButtonProps={
                            isIndividualEmission
                              ? {
                                  disabled: true,
                                  style: { display: 'none' },
                                }
                              : {}
                          }
                        />
                      </Grid>
                    )}
                    {showDeleteButton && (
                      <>
                        {!isIndividualEmission && isLastEntry && (
                          <Grid item xs={6}></Grid>
                        )}
                        <Grid item xs={1} style={{ marginTop: '1.5rem' }}>
                          <DeleteZaehlerLine
                            index={index}
                            fieldArrayName={ZAEHLER_EMISSIONSFAKTOREN.name}
                            disabled={props.disabled}
                            onClick={() => handleDelete(index)}
                          />
                        </Grid>
                      </>
                    )}
                    {showEditButton && (
                      <Grid item xs={1} style={{ marginTop: '1.5rem' }}>
                        <Tooltip
                          interactive
                          title="Bitte nutzen Sie die Bearbeitenfunktion nur um
                          historische Daten zu korrigieren."
                          PopperComponent={TooltipPopper(
                            <Typography>
                              Bitte nutzen Sie die Bearbeitenfunktion nur um
                              fehlerhafte historische Daten zu korrigieren. Für
                              Änderungen an dem Emissionsfaktor legen Sie bitte
                              über die Schaltfläche "Emissionsfaktoränderung"
                              einen neuen Eintrag an.
                            </Typography>
                          )}
                        >
                          <IconButton
                            onClick={() => handleEditRow(index)}
                            disabled={props.disabled}
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    )}
                  </Grid>
                );
              })}
            </>
          )}
        />
        <Grid item xs={6} style={{ marginTop: '0.5rem' }}>
          <AddOutlinedButton onClick={handleAddWerte} disabled={props.disabled}>
            Emissionsfaktoränderung
          </AddOutlinedButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const ZAEHLER_ZUSTANDSZAHL = {
  fieldName: 'zustandszahl',
  label: 'Zustandszahl',
  name: (index: number) =>
    `${ZAEHLER_ZUSTANDSZAHLEN.name}.${index}.${ZAEHLER_ZUSTANDSZAHL.fieldName}`,
  tooltip: `Die Zustandszahl wird von Gasversorgungsunternehmen zur Berechnung der tatsächlich entnommenen
            Menge an thermischer Energie in Kilowattstunden verwendet. Dabei wird das am Gaszähler aus der
            Zählerstandsdifferenz ermittelte Betriebsvolumen mit der Zustandszahl multipliziert. Die Zustandszahl
            finden Sie in der Regel auf der Abrechnung Ihres Versorgers, falls Sie die Zustandszahl nicht kennen,
            nehmen wir den Standardwert 0,97 an.`,
  validationSchema: validate(
    Yup.number()
      .min(0.8, 'Der Betrag muss mindestens 0,8 sein')
      .max(1.2, 'Der Betrag darf maximal 1,2 sein')
      .test({
        message: 'Bitte nur 2 Nachkommastellen eingeben',
        test: validateDecimalPlacesOfNumber(2),
      })
      .typeError('Bitte Betrag eingeben')
      .required('Bitte Betrag eingeben')
  ),
};

const ZaehlerZustandszahl = ({ disabled, index, value, ...props }: any) => (
  <LimboFormikNumberField
    name={ZAEHLER_ZUSTANDSZAHL.name(index)}
    label={ZAEHLER_ZUSTANDSZAHL.label}
    validate={ZAEHLER_ZUSTANDSZAHL.validationSchema}
    tooltip={ZAEHLER_ZUSTANDSZAHL.tooltip}
    disabled={disabled}
    value={value}
    required
    {...props}
  />
);

const ZUSTANDSZAHL_GUELTIG_BIS = {
  fieldName: 'gueltigBis',
  name: (index: number) =>
    `${ZAEHLER_ZUSTANDSZAHLEN.name}.${index}.${ZUSTANDSZAHL_GUELTIG_BIS.fieldName}`,
  label: 'Gültig bis',
  validationSchema: validate(
    Yup.date()
      .required('Bitte Datum auswählen')
      .typeError('Bitte Datum auswählen')
  ),
};

const ZustandszahlGueltigBis = ({ disabled, index, ...props }: any) => (
  <LimboFormikDatePicker
    name={ZUSTANDSZAHL_GUELTIG_BIS.name(index)}
    label={ZUSTANDSZAHL_GUELTIG_BIS.label}
    validate={ZUSTANDSZAHL_GUELTIG_BIS.validationSchema}
    disabled={disabled}
    dateFormat="yyyy-MM-dd"
    required
    {...props}
  />
);

export const ZAEHLER_ZUSTANDSZAHLEN = {
  name: 'zustandszahlen',
  validationSchema: validate(
    Yup.array().of(
      Yup.object().shape({
        gueltigBis: Yup.date(),
        zustandszahl: Yup.number(),
      })
    )
  ),
  defaultValue: [
    {
      [ZAEHLER_ZUSTANDSZAHL.fieldName]: 0.97,
    },
  ],
};

export const ZaehlerZustandszahlen = (props: any) => {
  const [rowsToEdit, setRowsToEdit] = React.useState<Array<number>>([]);
  const [newAddedRows, setNewAddedRows] = React.useState<Array<number>>([]);
  const [isCreatedMode, setIsCreateMode] = React.useState(false);
  const [field, , { setValue }] = useField<
    Array<{ [key: string]: string | number | null }>
  >({
    name: ZAEHLER_ZUSTANDSZAHLEN.name,
    validate: ZAEHLER_ZUSTANDSZAHLEN.validationSchema,
  });

  const handleAddWerte = React.useCallback(() => {
    setValue([...field.value, ZAEHLER_ZUSTANDSZAHLEN.defaultValue[0]]);
    const newRows = [...newAddedRows, field.value.length];
    setNewAddedRows(newRows);
  }, [field.value, newAddedRows, setValue]);

  React.useEffect(() => {
    if (!field.value) {
      setValue(ZAEHLER_ZUSTANDSZAHLEN.defaultValue);
      setIsCreateMode(true);
    }
  }, [field.value, setValue]);

  const handleDelete = React.useCallback(
    (indexToDelete) => {
      const lastItem = field.value.length - 1;
      const newFieldArray = [
        ...field.value.slice(0, indexToDelete),
        ...field.value.slice(indexToDelete + 1),
      ];
      if (indexToDelete === lastItem) {
        const [last, ...items] = [...newFieldArray].reverse();

        return setValue([
          ...items.reverse(),
          {
            [ZAEHLER_ZUSTANDSZAHL.fieldName]:
              last[ZAEHLER_ZUSTANDSZAHL.fieldName],
          },
        ]);
      }
      setValue(newFieldArray);
      const newRows = newAddedRows
        .filter((rowIndex) => rowIndex !== indexToDelete)
        .map((index) => (index > indexToDelete ? index - 1 : index));
      setNewAddedRows(newRows);
    },
    [field.value, newAddedRows, setValue]
  );

  const handleEditRow = React.useCallback(
    (index: number) => {
      const newRowsToEdit = [...rowsToEdit, index];
      setRowsToEdit(newRowsToEdit);
    },
    [rowsToEdit]
  );

  return (
    <Grid container direction="row" spacing={4}>
      <Grid item xs={12}>
        <FieldArray
          name={ZAEHLER_ZUSTANDSZAHLEN.name}
          render={() => (
            <>
              {field.value?.map((record, index) => {
                const isLastEntry = index === field.value.length - 1;
                const showEditButton =
                  !isCreatedMode &&
                  !rowsToEdit.includes(index) &&
                  !newAddedRows.includes(index);
                const showDeleteButton =
                  (isCreatedMode && field?.value?.length > 1) ||
                  (!isCreatedMode && newAddedRows.includes(index));

                return (
                  <Grid key={index} container direction="row" spacing={4}>
                    <Grid item xs={6}>
                      <ZaehlerZustandszahl
                        index={index}
                        value={record[ZAEHLER_ZUSTANDSZAHL.fieldName]}
                        disabled={props.disabled || showEditButton}
                      />
                    </Grid>
                    {!isLastEntry && (
                      <Grid
                        item
                        xs={showEditButton || showDeleteButton ? 5 : 6}
                      >
                        <ZustandszahlGueltigBis
                          index={index}
                          value={record[ZUSTANDSZAHL_GUELTIG_BIS.fieldName]}
                          disabled={
                            props.disabled ||
                            (showEditButton &&
                              !newAddedRows.includes(index + 1))
                          }
                        />
                      </Grid>
                    )}
                    {showDeleteButton && (
                      <>
                        {isLastEntry && <Grid item xs={5}></Grid>}
                        <Grid item xs={1}>
                          <DeleteZaehlerLine
                            index={index}
                            fieldArrayName={ZAEHLER_ZUSTANDSZAHLEN.name}
                            disabled={props.disabled}
                            onClick={() => handleDelete(index)}
                          />
                        </Grid>
                      </>
                    )}
                    {showEditButton && (
                      <Grid item xs={1}>
                        <Tooltip
                          interactive
                          title="Bitte nutzen Sie die Bearbeitenfunktion nur um
                          historische Daten zu korrigieren."
                          PopperComponent={TooltipPopper(
                            <Typography>
                              Bitte nutzen Sie die Bearbeitenfunktion nur um
                              fehlerhafte historische Daten zu korrigieren. Für
                              Änderungen an dem Emissionsfaktor legen Sie bitte
                              über die Schaltfläche "Zustandszahländerung" einen
                              neuen Eintrag an.
                            </Typography>
                          )}
                        >
                          <IconButton
                            onClick={() => handleEditRow(index)}
                            disabled={props.disabled}
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    )}
                  </Grid>
                );
              })}
            </>
          )}
        />
        <Grid item xs={6} style={{ marginTop: '0.5rem' }}>
          <AddOutlinedButton onClick={handleAddWerte} disabled={props.disabled}>
            Zustandszahländerung
          </AddOutlinedButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const ZAEHLERNUMMERN_STROM = {
  name: 'zaehlernummernStrom',
  label: 'Zählernummern',
  requiredErrorMsg: '',
  tooltip: '',
  validationSchema: validate(
    Yup.array().of(
      Yup.object().shape({
        gueltigBis: Yup.date(),
        zaehlernummer: Yup.string().required('Bitte Zählernummer eintragen'),
      })
    )
  ),
  defaultValue: [
    {
      [ZAEHLERNUMMER.fieldName]: '',
    },
  ],
};

export const ZaehlernummernStrom = (props: any) => {
  const { values } = useFormikContext();
  const erfassungsType = getIn(values, ZAEHLER_DATA_UNIT.name);

  return (
    <LimboFormikZaehlernummern
      name={ZAEHLERNUMMERN_STROM.name}
      validationSchema={ZAEHLERNUMMERN_STROM.validationSchema}
      defaultValue={ZAEHLERNUMMERN_STROM.defaultValue}
      erfassungsTyp={erfassungsType}
      zaehlerTyp={EnergieSparte.Strom}
      disabled={props.disabled}
      messwertAttributeKey="wertStrom"
      nulldurchgangAttributeKey="nulldurchgangStrom"
    />
  );
};

export const ZAEHLERNUMMERN_WAERME = {
  name: 'zaehlernummernWaerme',
  label: 'Zählernummern',
  requiredErrorMsg: '',
  tooltip: '',
  validationSchema: validate(
    Yup.array().of(
      Yup.object().shape({
        gueltigBis: Yup.date(),
        zaehlernummer: Yup.string().required('Bitte Zählernummer eintragen'),
      })
    )
  ),
  defaultValue: [
    {
      [ZAEHLERNUMMER.fieldName]: '',
    },
  ],
};

export const ZaehlernummernWaerme = (props: any) => {
  const { values } = useFormikContext();
  const erfassungsType = getIn(values, ZAEHLER_DATA_UNIT.name);

  return (
    <LimboFormikZaehlernummern
      name={ZAEHLERNUMMERN_WAERME.name}
      validationSchema={ZAEHLERNUMMERN_WAERME.validationSchema}
      defaultValue={ZAEHLERNUMMERN_WAERME.defaultValue}
      erfassungsTyp={erfassungsType}
      zaehlerTyp={EnergieSparte.Waerme}
      disabled={props.disabled}
      messwertAttributeKey="wertWaerme"
      nulldurchgangAttributeKey="nulldurchgangWaerme"
    />
  );
};
