import { useSnackbar } from 'notistack';
import * as React from 'react';
import httpService, { fetcher } from 'src/utils/httpService';
import useSWR, { mutate } from 'swr';
import { ENDPOINTS } from '../../config';
import {
  UNTERKATEGORIE_SONSTIGES_BY_KUNDEN_TYP,
  UNTERKATEGORIE_STRASSENBELEUCHTUNG,
} from './helper';

import { SEARCH_FILTER_KEY } from 'src/components/molecules/limbo-search-filter';
import { useSearchQuery } from 'src/utils/hooks/search-query';
import { alphabeticalSort } from 'src/utils/sorting';
import { useVerwaltung } from '../Einstellungen/hooks';
import { getLiegenschaftenPropsByUnterkategorie } from './entity/helper';

const prepareLiegenschaftForBackend = (liegenschaft) => {
  const {
    vorname,
    nachname,
    email,
    telefon,
    funktion,
    gruppe,
    ...liegenschaftDetails
  } = liegenschaft;
  const hasAnsprechpartnerData =
    vorname || nachname || email || telefon || funktion;
  const ansprechpartner = hasAnsprechpartnerData
    ? {
        ...(vorname ? { vorname } : {}),
        ...(nachname ? { nachname } : {}),
        ...(email ? { email } : {}),
        ...(telefon ? { telefon } : {}),
        ...(funktion ? { funktion } : {}),
      }
    : null;

  return {
    ...liegenschaftDetails,
    ...(ansprechpartner ? { ansprechpartner: ansprechpartner } : {}),
    ...(gruppe?.trim().length > 0 ? { gruppe: gruppe.trim() } : {}),
  };
};

const removeEmptyFlaechen = (data) => {
  const { flaechen, ...liegenschaft } = data;
  const validFlaechen = flaechen
    ? flaechen.filter(({ flaeche }) => !!flaeche)
    : [];
  return {
    ...liegenschaft,
    ...(validFlaechen.length > 0 ? { flaechen: validFlaechen } : {}),
  };
};

const removeNullValuesFromLiegenschaft = (data, kundenTyp) => {
  const liegenschaft = removeEmptyFlaechen(data);
  const liegenschaftenProps = getLiegenschaftenPropsByUnterkategorie(
    liegenschaft.gebaeudeUnterkategorie,
    kundenTyp
  );
  return Object.keys(liegenschaft).reduce((acc, key) => {
    if (liegenschaftenProps[key].typ === 'array') {
      const arrayWithoutNullValues = liegenschaft[key]?.map((item) =>
        Object.keys(item).reduce((acc, itemKey) => {
          const invalidNumber =
            typeof item[itemKey] === 'number' ? isNaN(item[itemKey]) : false;
          return item[itemKey] != null && !invalidNumber
            ? { ...acc, [itemKey]: item[itemKey] }
            : acc;
        }, {})
      );
      return { ...acc, [key]: arrayWithoutNullValues };
    } else {
      const invalidNumber =
        typeof liegenschaft[key] === 'number'
          ? isNaN(liegenschaft[key])
          : false;
      return liegenschaft[key] != null && !invalidNumber
        ? { ...acc, [key]: liegenschaft[key] }
        : acc;
    }
  }, {});
};

interface UseLiegenschaftenProps {
  id?: string;
}

export const useLiegenschaften = ({ id }: UseLiegenschaftenProps = {}) => {
  const { verwaltung } = useVerwaltung();
  const url = React.useMemo(
    () =>
      id ? ENDPOINTS.API_LIEGENSCHAFT_BY_ID(id) : ENDPOINTS.API_LIEGENSCHAFTEN,
    [id]
  );

  const { data, error } = useSWR(url, fetcher, {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
  });

  const refetch = React.useCallback(() => {
    mutate(url);
  }, [url]);

  const { enqueueSnackbar } = useSnackbar();

  const createLiegenschaft = React.useCallback(
    async (inputData) => {
      const dataWithBackendStructur = prepareLiegenschaftForBackend(inputData);
      const liegenschaftenProps = getLiegenschaftenPropsByUnterkategorie(
        dataWithBackendStructur.gebaeudeUnterkategorie,
        verwaltung.kundenTyp
      );
      const filteredData = Object.keys(liegenschaftenProps).reduce(
        (acc, property) => ({
          ...acc,
          [property]: dataWithBackendStructur[property],
        }),
        {}
      );
      const data = removeNullValuesFromLiegenschaft(
        filteredData,
        verwaltung.kundenTyp
      );
      const response = await httpService
        .post(ENDPOINTS.API_LIEGENSCHAFTEN, data)
        .catch((e) => {
          enqueueSnackbar('Liegenschaft konnte nicht erstellt werden', {
            variant: 'error',
          });
          return e;
        });
      refetch();
      return response;
    },
    [enqueueSnackbar, refetch, verwaltung.kundenTyp]
  );

  const updateLiegenschaft = React.useCallback(
    async (id, inputData) => {
      const dataWithBackendStructur = prepareLiegenschaftForBackend(inputData);
      const liegenschaftenProps = getLiegenschaftenPropsByUnterkategorie(
        dataWithBackendStructur.gebaeudeUnterkategorie,
        verwaltung.kundenTyp
      );
      const filteredData = Object.keys(liegenschaftenProps).reduce(
        (acc, property) => ({
          ...acc,
          [property]: dataWithBackendStructur[property],
        }),
        {}
      );
      const data = removeNullValuesFromLiegenschaft(
        filteredData,
        verwaltung.kundenTyp
      );
      const response = await httpService
        .put(`${ENDPOINTS.API_LIEGENSCHAFTEN}/${id}`, data)
        .catch((e) => {
          enqueueSnackbar('Liegenschaft konnte nicht aktualisiert werden', {
            variant: 'error',
          });
          return e;
        });
      mutate(`${ENDPOINTS.API_LIEGENSCHAFTEN}/${id}`);
      mutate(`${ENDPOINTS.API_LIEGENSCHAFTEN}`);
      return response;
    },
    [enqueueSnackbar, verwaltung.kundenTyp]
  );

  const deleteLiegenschaft = React.useCallback(
    async (id) => {
      const response = await httpService
        .delete(`${ENDPOINTS.API_LIEGENSCHAFTEN}/${id}`)
        .catch(() => {
          enqueueSnackbar('Liegenschaft konnte nicht gelöscht werden', {
            variant: 'error',
          });
        });
      refetch();
      mutate(ENDPOINTS.API_ZAEHLPUNKTE);
      return response;
    },
    [enqueueSnackbar, refetch]
  );

  const handleExportLiegenschaften = React.useCallback(() => {
    return httpService.get(ENDPOINTS.API_LIEGENSCHAFTEN_EXPORT).catch((e) => {
      enqueueSnackbar('Liegenschaften konnten nicht exportiert werden', {
        variant: 'error',
      });
      return e;
    });
  }, [enqueueSnackbar]);

  return {
    createLiegenschaft,
    updateLiegenschaft,
    deleteLiegenschaft,
    handleExportLiegenschaften,
    refetch,
    liegenschaften: data || [],
    isLoading: !error && !data,
    isError: error,
  };
};

export const useGridStyleConfig = (
  unterkategorie: number,
  hasAddress: boolean
) => {
  const { verwaltung } = useVerwaltung();

  const styleConfig = React.useMemo(() => {
    switch (unterkategorie) {
      case UNTERKATEGORIE_STRASSENBELEUCHTUNG:
        return {
          grid: {
            display: 'grid',
            gridTemplateColumns: 'repeat(12, 1fr)',
            gridGap: '1rem',
          },
          allgemeineAngaben: {
            column: '1 / span 6',
            row: '1 / span 3',
          },
          ansprechpartner: {
            column: 'span 6',
            row: '1 / span 1',
          },
          energiekennwerte: {
            column: 'span 6',
            row: 'span 2',
          },
        };
      case UNTERKATEGORIE_SONSTIGES_BY_KUNDEN_TYP(verwaltung.kundenTyp):
        return hasAddress
          ? {
              grid: {
                display: 'grid',
                gridTemplateColumns: 'repeat(12, 1fr)',
                gridTemplateRows: '40% 60%',
                gridGap: '1rem',
                paddingBottom: '1rem',
              },
              allgemeineAngaben: {
                column: '1 / span 5',
                row: '1 / span 2',
              },
              ansprechpartner: {
                column: 'span 4',
                row: '1 / span 1',
              },
              cardMap: {
                column: 'span 3',
                row: '1 / span 2',
              },
            }
          : {
              grid: {
                display: 'grid',
                gridTemplateColumns: 'repeat(12, 1fr)',
                gridGap: '1rem',
              },
              allgemeineAngaben: {
                column: '1 / span 6',
                row: '1 / span 3',
              },
              ansprechpartner: {
                column: 'span 6',
                row: '1 / span 1',
              },
            };
      default:
        return {
          grid: {
            display: 'grid',
            gridTemplateColumns: 'repeat(12, 1fr)',
            gridTemplateRows: '40% 60%',
            gridGap: '1rem',
            paddingBottom: '1rem',
          },
          allgemeineAngaben: {
            column: '1 / span 5',
            row: '1 / span 2',
          },
          ansprechpartner: {
            column: 'span 4',
            row: '1 / span 1',
          },
          energiekennwerte: {
            column: 'span 4',
            row: 'span 1',
          },
          cardMap: {
            column: 'span 3',
            row: '1 / span 2',
          },
        };
    }
  }, [hasAddress, unterkategorie, verwaltung.kundenTyp]);

  return {
    gridConfig: styleConfig,
  };
};

export const useDropDownData = (
  liegenschaften: {
    id: string;
    liegenschaftsbezeichnung: string;
    gebaeudeUnterkategorie: number;
  }[]
) => {
  const dropDownData = React.useMemo(
    () =>
      liegenschaften
        .map((liegenschaft) => ({
          value: liegenschaft.id,
          label: liegenschaft.liegenschaftsbezeichnung,
          gebaeudeUnterkategorie: liegenschaft.gebaeudeUnterkategorie,
        }))
        .sort(alphabeticalSort('label')),
    [liegenschaften]
  );
  return {
    liegenschaftenDropDown: dropDownData,
  };
};

export const GRUPPE_FILTER_KEY = 'gruppe';
export const GEBAEUDEKATEGORIE_FILTER_KEY = 'gebaeudekategorie';

export const useLiegenschaftenFilter = ({ locations }) => {
  const { filterData } = useSearchQuery();

  const filterQueryGebaeudekategorie = filterData[GEBAEUDEKATEGORIE_FILTER_KEY];
  const filterQueryGruppe = filterData[GRUPPE_FILTER_KEY];
  const filterQuerySearch = filterData[SEARCH_FILTER_KEY];

  const gebaeudekategorieFilter =
    typeof filterQueryGebaeudekategorie === 'object'
      ? filterQueryGebaeudekategorie[0]
      : 'all';
  const gruppenFilter = (
    typeof filterQueryGruppe === 'object' ? filterQueryGruppe[0] : 'all'
  ).replace(/%20/g, ' ');
  const searchFilter =
    typeof filterQuerySearch === 'object' ? filterQuerySearch[0] : null;

  const filterQueryAlphaSort = filterData['alphaSort'];
  const alphaSortFilter =
    typeof filterQueryAlphaSort === 'object' ? filterQueryAlphaSort[0] : 'asc';

  const filteredAndSortedLiegenschaften = React.useMemo(() => {
    const filteredLocationsByGebaeudeKategorie =
      gebaeudekategorieFilter !== 'all'
        ? locations.filter(
            ({ gebaeudeKategorie }) =>
              gebaeudeKategorie.toString() === gebaeudekategorieFilter
          )
        : locations;

    const filteredLocationsByGebaeudeKategorieAndGruppe =
      gruppenFilter !== 'all'
        ? filteredLocationsByGebaeudeKategorie.filter(
            ({ gruppe }) => gruppe === gruppenFilter
          )
        : filteredLocationsByGebaeudeKategorie;

    const filteredLocationsByGebaeudeKategorieAndGruppeAndSearch =
      !!searchFilter
        ? filteredLocationsByGebaeudeKategorieAndGruppe.filter(
            ({ liegenschaftsbezeichnung, address }) =>
              liegenschaftsbezeichnung.toLowerCase().includes(searchFilter) ||
              address?.addressString?.toLowerCase().includes(searchFilter)
          )
        : filteredLocationsByGebaeudeKategorieAndGruppe;

    return alphaSortFilter === 'asc'
      ? filteredLocationsByGebaeudeKategorieAndGruppeAndSearch.sort(
          alphabeticalSort('liegenschaftsbezeichnung')
        )
      : filteredLocationsByGebaeudeKategorieAndGruppeAndSearch
          .sort(alphabeticalSort('liegenschaftsbezeichnung'))
          .reverse();
  }, [
    gebaeudekategorieFilter,
    locations,
    gruppenFilter,
    searchFilter,
    alphaSortFilter,
  ]);

  return {
    filteredAndSortedLiegenschaften,
  };
};

export const useLiegenschaftenGruppen = () => {
  const { data, error } = useSWR(
    ENDPOINTS.API_LIEGENSCHAFTEN_GRUPPEN,
    fetcher,
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    }
  );

  const refetch = React.useCallback(() => {
    mutate(ENDPOINTS.API_LIEGENSCHAFTEN_GRUPPEN);
  }, []);

  const updateGruppen = React.useCallback(
    async (id, liegenschaftenIds) => {
      const response = await httpService
        .put(`${ENDPOINTS.API_LIEGENSCHAFTEN_GRUPPEN}/${id}`, {
          liegenschaftenIds,
        })
        .catch((e) => e);
      refetch();
      return response;
    },
    [refetch]
  );

  return {
    refetch,
    updateGruppen,
    gruppen: data || [],
    isLoading: !error && !data,
    isError: error,
  };
};
